import React from 'react'
import { Link } from "gatsby"
import * as headerStyle from './header.module.css'


function Nav({isOpen, isTop, onClickMenu}) {
    
    let navMenu = null
    // if( isTop ) {
    //     navMenu = (
    //         <ol className={headerStyle.navList}>
    //             {/* eslint-disable-next-line*/}
    //             <li><a href="#"      onClick={onClickMenu}>HOME</a></li>
    //             <li><a href="#top-1" onClick={onClickMenu}>お知らせ</a></li>
    //             <li><a href="#top-2" onClick={onClickMenu}>診療のご案内</a></li>
    //             <li><a href="#top-3" onClick={onClickMenu}>問診票</a></li>
    //             <li><a href="#top-4" onClick={onClickMenu}>診療時間</a></li>
    //             <li><a href="#top-5" onClick={onClickMenu}>アクセス</a></li>
    //             <li><a href="#top-6" onClick={onClickMenu}>当院について</a></li>
    //         </ol>
    //     )
    // } else {
        navMenu = (
            <ol className={headerStyle.navList}>
                <li><Link to="/">HOME</Link></li>
                <li><Link to="/news/">お知らせ</Link></li>
                <li><Link to="/consultation/">診療のご案内</Link></li>
                <li><Link to="/consultation/#interviewSheet">問診票</Link></li>
                <li><Link to="/access/">診療時間・アクセス</Link></li>
                <li><Link to="/about/">当院について</Link></li>
            </ol>
        )
    // }

    return (
        <div className={headerStyle.navContainer}>
            <nav className={isOpen ? headerStyle.nav_open : headerStyle.nav}>
                { navMenu }
            </nav>
        </div>
    )
}

export default Nav
