import React from 'react'
import * as burgerStyle from './burger.module.css'

function Burger({open, setOpen}) {
    return (
        <button className={open ? burgerStyle.burger_open : burgerStyle.burger} onClick={() => {setOpen(!open)}}>
            <span />
            <span />
            <span />
        </button>
    )
}

export default Burger
