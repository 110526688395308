import React, {useState, useEffect} from 'react'
import Header from '/src/components/header'
import Contact from '/src/components/Contact'
import Footer from '/src/components/footer'
import * as layoutStyle from '/src/layout/default.module.css'

export default function Layout(props) {
    // get header height
    const [headerHeight, setHeaderHieght] = useState(200)
    // header fix
    const [isScrolled, setIsScrolled] = useState(false)
    useEffect(() => {

        setHeaderHieght(document.getElementById('header').offsetHeight)
        
        let isMounted = true
        function onScroll(){
            if(isMounted !== true) return
            if(window.scrollY > 200) {
                setIsScrolled(true)
            } else {
                setIsScrolled(false)
            }
        }
        window.addEventListener('scroll', onScroll, {passive: true})
        return function() {
            isMounted = false
            window.removeEventListener('scroll', onScroll)
        }
    }, [props.location])

    return (
        <div className={layoutStyle.contentsWrapper}>
            <Header isScrolled={isScrolled} location={props.location} />
            { props.location.pathname === "/" && <Contact isScrolled={isScrolled} />}
            <div className={layoutStyle.contents} style={isScrolled ? {paddingTop: headerHeight} : {}}>
                {props.children}
            </div>
            <Footer />
        </div>
    )
}
