// extracted by mini-css-extract-plugin
export var footer = "footer-module--footer--ov5Kw";
export var footerInner = "footer-module--footer-inner--8CB0T";
export var toTop = "footer-module--to-top--W1cm6";
export var banner = "footer-module--banner--8qAVr";
export var bannerLink = "footer-module--banner-link--skbrD";
export var contents = "footer-module--contents--yn6hp";
export var contact = "footer-module--contact--lsqOm";
export var small = "footer-module--small--W-Rg+";
export var businessHours = "footer-module--business-hours--9a5Ja";
export var businessHoursGroup = "footer-module--business-hours-group--kPnPX";
export var tel = "footer-module--tel--13gbc";
export var mapInfo = "footer-module--map-info--6IoL+";
export var mapLink = "footer-module--map-link--hVI38";
export var copyright = "footer-module--copyright--NzlVR";