import React, { useState } from 'react'
import { Link } from "gatsby"
// import {css} from '@emotion/react'
import Burger from '/src/components/burger.js'
import Nav from '/src/components/Nav.js'
import * as headerStyle from './header.module.css'
import headerLogo from "/src/images/header_logo.png"


function Header(props) {
    const [open, setOpen] = useState(false)
    const isTop    = props.location.pathname === "/"
    const isMobile = typeof window !== 'undefined' && window.matchMedia('(max-width: 768px)').matches
    
    function onClickMenu(e) {
        e.preventDefault()
        const href = e.target.getAttribute('href')
        const target = href === '#' || href === '' ? '' : document.getElementById(href.replace('#', ''))
        const targetPosition = target === '' ? 0 : window.scrollY + target.getBoundingClientRect().top
        window.scrollTo({top: targetPosition, behavior: 'smooth'})
        if(isMobile < 769) {
            setTimeout(()=>{
                setOpen(false)
            }, 200)
        }
    }
    
    return (
        <header id="header" className={ props.isScrolled ? headerStyle.header_fixed : headerStyle.header }>
            <div className={headerStyle.header__inner}>
                <h1 className={headerStyle.logo}><Link to="/"><img src={headerLogo} width="1000" height="106" alt="平安堂こころのクリニック"/></Link></h1>
                <Burger open={open} setOpen={setOpen} />
                {
                    (!isTop || isMobile || props.isScrolled) &&
                    <Nav isOpen={open} isTop={isTop} onClickMenu={onClickMenu} />
                }
            </div>
        </header>
    )
}

export default Header
