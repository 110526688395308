import React, { useState, useEffect } from 'react'
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import * as footerStyle from './footer.module.css'
import tel from '../images/tel.png'
import totop from '../images/totop.png'

function Footer() {
    const [styleObj, setStyleObj] = useState({pointerEvents: 'none', opacity: '0'})
    function onScroll (footer) {
        if(window.scrollY < 200) {
            setStyleObj(()=>{return{pointerEvents: 'none', opacity:'0'}})
        } else if(window.scrollY + document.documentElement.clientHeight > document.body.offsetHeight - footer.offsetHeight) {
            setStyleObj(() => {
                return {
                position: 'absolute',
                top: '-80px',
                bottom: 'auto',
            }})
        } else {
            setStyleObj(()=>{return{}})
        }
    }
    useEffect(() => {
        let isMounted = true
        window.addEventListener('scroll',(e) => {
            if(isMounted !== true) return
            onScroll(document.getElementById('footer'))
        },{passive: true})
        return function() { isMounted = false }
    }, [])

    function preventTelOnPC(e) {
        const ua       = navigator.userAgent.toLowerCase()
        const isMobile = /iphone/.test(ua)||/android(.+)?mobile/.test(ua)
        if (!isMobile) {
            e.preventDefault()
        }
    }

    return (
        <footer id="footer" className={footerStyle.footer}>
            <button className={footerStyle.toTop} style={styleObj} onClick={() => {window.scrollTo({top: 0, behavior: 'smooth'})}}>
                <img src={totop} alt="TOP" />
            </button>
            <div className={footerStyle.footerInner}>
                <div className={footerStyle.contents}>
                    <p className={footerStyle.contact}>ご予約・お問い合わせ<br /><a href="tel:047-407-3819" className={footerStyle.tel} onClick={preventTelOnPC}><img src={tel} alt="TEL. 047-407-3819" /></a><br /><span className={footerStyle.businessHours}><span className={footerStyle.businessHoursGroup}>予約受付時間</span><br className="visible-xs" />診療日の 9:00一12:00／14:00一17:00</span></p>
                    <p className={footerStyle.mapInfo}>平安堂こころのクリニック<br />千葉県船橋市前原西2-11-11 <br className="visible-xs" />平安堂医療ビル4F<Link to="/access/" className={footerStyle.mapLink} >map</Link></p>
                </div>
                <small className={footerStyle.copyright}>&copy;Heiando kokoro no clinic. All rights reserved.</small>
            </div>
        </footer>
    )
}

export default Footer

