import React, {useState, useEffect} from 'react'
import Modal from 'react-modal'
import * as contactStyle from './Contact.module.css'
import tel from '../images/tel.png'

export default function Contact(props) {
    const linkClass = props.isScrolled === true ? contactStyle.fixed : contactStyle.link;
    const [styleObj, setStyleObj] = useState({pointerEvents: 'none', opacity: '0'})
    function onScroll () {
        if(document.body.clientWidth > 768) {
            return
        }
        if(window.scrollY < 200) {
            setStyleObj(()=>{return{pointerEvents: 'none', opacity:'0'}})
        } else {
            setStyleObj(()=>{return{}})
        }
    }
    useEffect(() => {
        let isMounted = true
        if(document.body.clientWidth > 768){
            setStyleObj(()=>{return{}})
            return
        }
        window.addEventListener('scroll',() => {
            if(!isMounted) return
            onScroll()
        },{passive: true})
        return function() { isMounted = false }
    }, [])

    // modal
    Modal.setAppElement("#___gatsby")
    const [modalIsOpen, setIsOpen] = React.useState(false)
    const handleOpenModal = (e) => {
        e.preventDefault()
        setIsOpen(true)
    }
    const handleCloseModal = () => {
        setIsOpen(false)
    }
    const modalStyles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(77, 77, 77, 0.7)',
            zIndex: 2000
        },
        content: {
            position: 'absolute',
            width: '500px',
            minHeight: '390px',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
            border: '1px solid #ccc',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '12px',
            outline: 'none',
            padding: '0'
        }
    }

    const [isMobile, setIsMobile] = useState(true)
    useEffect(() => {

        if(typeof window === 'undefined') {
            setIsMobile(false)
        } else {
            const ua = navigator.userAgent.toLowerCase()
            const boolean = /iphone/.test(ua)||/android(.+)?mobile/.test(ua) 
            setIsMobile(boolean)
        }
    }, [])
    if (isMobile) {
        return (
            <a href="tel:047-407-3819" style={styleObj} className={linkClass}>ご予約</a>
        )
    } else {
        return (
            <>
                <Modal 
                    isOpen={modalIsOpen}
                    onRequestClose={() => handleCloseModal()}
                    shouldCloseOnEsc={true}
                    shouldCloseOnOverlayClick={true}
                    style={modalStyles}
                >
                    <button className={contactStyle.modalClose} onClick={handleCloseModal} aria-label="close" />
                    <h2 className={contactStyle.modalHeading}>ご予約・お問い合わせ</h2>
                    <div className={contactStyle.modalContent}>
                        <p className={contactStyle.modalText}>お気軽にお問い合わせください</p>
                        <p className={contactStyle.tel}><span><img src={tel} alt="TEL. 047-407-3819" /></span></p>
                        <p className={contactStyle.businessHours}>予約受付時間<br />診療日の 9:00一12:00／14:00一17:00</p>
                    </div>
                </Modal>
                <a href="#modal" style={styleObj} className={linkClass} onClick={handleOpenModal}>ご予約</a>
            </>
        )
    }
}